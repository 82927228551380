<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-icon>fas fa-filter</v-icon>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                    <!--#region Theme  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t(val.EFTheme.label) }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field
                                v-model="val.EFTheme.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.EFTheme.value = null"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region RegNumber  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t(val.RegNumber.label) }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field
                                v-model="val.RegNumber.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.RegNumber.value = null"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region Counter  -->
                    <v-row no-gutters v-if="val.Counter">
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t(val.Counter.label)}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <v-select
                                return-object
                                :items="counters"
                                v-model="val.Counter.value"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.Counter.value = null"
                                append-icon="fas fa-chevron-down"
                                :menu-props="{ bottom: true, offsetY: true }"
                            ></v-select>

                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region NomenclatureId  -->
                    <v-row no-gutters v-if="val.NomenclatureId">
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t(val.NomenclatureId.label)}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            
                            <v-select
                                v-model="val.NomenclatureId.value"
                                :items="nomenclatures"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                multiple
                                return-object                                    
                                outlined
                                dense
                                hide-details
                                clearable
                                append-icon="fas fa-chevron-down"                                    
                                @click:clear="val.NomenclatureId.value = null"
                                :menu-props="{ bottom: true, offsetY: true }"
                            >

                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-text-field
                                                :placeholder="$t('Поиск')"
                                                class="mb-2 autocomplete-search"
                                                hide-details
                                                dense
                                                clearable
                                                autofocus
                                                v-model="nomenclatureSearch"
                                            >
                                            </v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                            </v-select>

                        </v-col>
                    </v-row>
                    
                    <!--#endregion -->

                    <!--#region JuridicalDocType  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t(val.JuridicalDocType.label) }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <v-select
                                return-object
                                :items="juridicalDocTypeItems"
                                v-model="val.JuridicalDocType.value"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                :search-input.sync="juridicalDocTypeSearchInput"
                                @change="juridicalDocTypeSearchInput = ''"
                                @click:clear="val.JuridicalDocType.value = null"
                                append-icon="fas fa-chevron-down"
                                :menu-props="{ bottom: true, offsetY: true }"
                            >
                                <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchJuridicalDocType"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                            </v-select>

                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region OutNumber  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t(val.OutNumber.label)}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field
                                v-model="val.OutNumber.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.OutNumber.value = null"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region HigherNumber  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t(val.HigherNumber.label)}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field
                                v-model="val.HigherNumber.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.HigherNumber.value = null"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region Correspondent  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.Correspondent.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="correspondentItems"
                                    v-model="val.Correspondent.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    :search-input.sync="correspondentSearchInput"
                                    @change="correspondentSearchInput = ''"
                                    @click:clear="val.Correspondent.value = []"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="search"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>   
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region ExecuterName  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.ExecuterName.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                            <v-text-field
                                v-model="val.ExecuterName.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.ExecuterName.value = null"
                            >
                            </v-text-field>

                            </v-col>
                        </v-row>
                    <!--#endregion -->
                    
                    <!--#region SignerName  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.SignerName.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                            <v-text-field
                                v-model="val.SignerName.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.SignerName.value = null"
                            >
                            </v-text-field>

                            </v-col>
                        </v-row>
                    <!--#endregion -->                    

                    <!--#region RegDateFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegDateFrom.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="regDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.RegDateFrom.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.RegDateFrom.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.RegDateFrom.value"
                                        @input="regDateFromMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region RegDateTo  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegDateTo.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="regDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.RegDateTo.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.RegDateTo.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.RegDateTo.value"
                                        @input="regDateToMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region Recipients  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.Recipients.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="recipients"
                                    v-model="val.Recipients.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    @click:clear="val.Recipients.value = []"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchRecipients"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->
                    
                    <!--#region OutDateFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.OutDateFrom.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="outDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.OutDateFrom.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.OutDateFrom.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.OutDateFrom.value"
                                        @input="outDateFromMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region OutDateTo  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.OutDateTo.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="outDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.OutDateTo.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.OutDateTo.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.OutDateTo.value"
                                        @input="outDateToMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region Languages  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.Language.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="languagesItems"
                                    v-model="val.Language.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.Language.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region Executers  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.Executers.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="innerExecuters"
                                    v-model="val.Executers.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    @click:clear="val.Executers.value = []"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchExecuters"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region ResolutionAuthor  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.ResolutionAuthor.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="itemsResolutionAuthor"
                                    v-model="val.ResolutionAuthor.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    @click:clear="val.ResolutionAuthor.value = []"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchResolutionAuthor"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region HasAnswer  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.HasAnswer.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="hasAnswerValues"
                                    v-model="val.HasAnswer.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.HasAnswer.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region ExecuteStatus  -->                        
                        <v-row no-gutters v-if="val.ExecuteStatus">
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.ExecuteStatus.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="executeStatusValues"
                                    v-model="val.ExecuteStatus.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    @click:clear="val.ExecuteStatus.value = []"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region IsIncomingAnswer  -->
                        <v-row no-gutters v-if="val.IsIncomingAnswer">
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.IsIncomingAnswer.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="isIncomingAnswerValues"
                                    v-model="val.IsIncomingAnswer.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.IsIncomingAnswer.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->
                    
                    <!--#region IsDuplicate  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.IsDuplicate.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="isDuplicateValues"
                                    v-model="val.IsDuplicate.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.IsDuplicate.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="save" 
                        v-if="isFormValid"
                    >
                        {{$t("Применить_фильтр")}}                    
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { httpAPI } from "@/api/httpAPI";
import { mapActions } from 'vuex';
import sys from '@/services/system';

export default {
    name: "IncomingsExFilter",
    props: {
        value:
        {
            type: Object,
            default: null
        }
    },
    asyncComputed: {
        nomenclatures : {
            async get() {
                if (this.nomenclatureSearch) {
                    let selectedIds = this.val.NomenclatureId.value.length ? this.val.NomenclatureId.value.map(i => i.id) : [];

                    return (await this.$store.dispatch('documents/references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Value: `${x[1]} ${x[2]}`, type: parseInt(x[3]) }))
                        .filter(x => x.type === 3 && sys.checkSearch(x.Value, this.nomenclatureSearch) || selectedIds.includes(x.id));
                }
                else
                    return (await this.$store.dispatch('documents/references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Value: `${x[1]} ${x[2]}`, type: parseInt(x[3]) }))
                        .filter(x => x.type === 3);
            },
            default: [],
            watch: ['nomenclatureSearch']
        }
    },
    data() {
        return {
            title: "Фильтр",
            isFormValid: true,
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            val: JSON.parse(JSON.stringify(this.value)),
            
            correspondentItems: [],
            recipients: [],
            innerExecuters: [],
            itemsResolutionAuthor: [],

            regDateFromMenu: false,
            regDateToMenu: false,
            outDateFromMenu: false,
            outDateToMenu: false,

            languagesItems: [],
            hasAnswerValues: [
                { id: 1, Value: this.$t("Да") },
                { id: 2, Value: this.$t("Нет") }
            ],
            executeStatusValues: [
                { id: 'Новое', Value: this.$t("Новое") },
                { id: 'В_работе', Value: this.$t("В_работе") },
                { id: 'Исполнено', Value: this.$t("Исполнено") },
                { id: 'Просрочено', Value: this.$t("Просрочено") }
            ],
            isDuplicateValues: [
                { id: 1, Value: this.$t("Да") }
            ],
            isIncomingAnswerValues: [
                { id: 1, Value: this.$t("Да") }
            ],
            counters: [],
            correspondentSearchInput: "",

            nomenclatureSearch: "",

            juridicalDocTypeItems: [],
            juridicalDocTypeSearchInput: ""
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    methods: {
        async initialize() {
            this.correspondentItems = (await this.getContractors())
            .map(i =>
            ({
                id: i[0],
                Value: i[1]
            }));

            this.recipients = this.innerExecuters = this.itemsResolutionAuthor = (await this.getWorkplaces()).InnerExecuters.Executers
                .map(i => ({ id: i[0], Value: i[1], employeeId: i[2], jobTitle: i[3] }));

            this.languagesItems = await this.getReference({ id: 1000 });
            this.carrierTypeItems = await this.getReference({ id: 1024 });
            this.juridicalDocTypeItems = await this.getReference({ id: 1001 });

            let response = await httpAPI({
                url: `/api/references/counters/${3}`, // 3 означает тип входящих
                method: 'GET'
            });

            this.counters = response?.data?.Payload?.Data?.map( x => ({ id: x[0], Value: `${x[2]} ${x[1]}` })) ?? [];
        },
        ...mapActions('documents/references', ['getContractors', 'getReference', 'getWorkplaces']),
        ...mapActions(['setOverlayVisible']),
        async open(options = {})
        {
            this.val = JSON.parse(JSON.stringify(this.value)),
            this.visible = true;
            this.options = Object.assign(this.options, options);

            this.setOverlayVisible({ visible: true });
            this.initialize();
            this.setOverlayVisible({ visible: false });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({                
                isCancelled: true,
            });                
        },
        save() {
            this.visible = false;
            this.$emit('input', this.val);
            this.resolve();
        },
        handleDate(source) {
            var formatedDate = sys.dateFormat(source, 'DD.MM.YYYY');
            return formatedDate ?? "";
        },
        async search(e) {
            if (e) {
                let selectedIds = this.val.Correspondent.value.length ? this.val.Correspondent.value.map(i => i.id): [];                    
                this.correspondentItems = (await this.getContractors()).map(i => ({id: i[0], Value: i[1]})).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.correspondentItems = (await this.getContractors()).map(i => ({id: i[0], Value: i[1]}));
        },
        async searchRecipients(e) {
            if (e) {
                let selectedIds = this.val.Recipients.value.length ? this.val.Recipients.value.map(i => i.id): [];                    
                this.recipients = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[0], Value: i[1]})).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.recipients = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[0], Value: i[1]}));
        },
        async searchExecuters(e) {
            if (e) {
                let selectedIds = this.val.Executers.value.length ? this.val.Executers.value.map(i => i.id): [];                    
                this.innerExecuters = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[0], Value: i[1]})).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.innerExecuters = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[0], Value: i[1]}));
        },
        async searchResolutionAuthor(e) {
            if (e) {
                let selectedIds = this.val.ResolutionAuthor.value.length ? this.val.ResolutionAuthor.value.map(i => i.id): [];                    
                this.itemsResolutionAuthor = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[0], Value: i[1]})).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.itemsResolutionAuthor = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[0], Value: i[1]}));
        },
        async searchJuridicalDocType(e) {
            if (e) {
                let selectedId = this.val.JuridicalDocType.value ? this.val.JuridicalDocType.value.id : null;                    
                this.juridicalDocTypeItems = (await this.getReference({ id: 1001 })).filter( i => sys.checkSearch(i.Value, e) || selectedId == i.id);
            }
            else
                this.juridicalDocTypeItems = (await this.getReference({ id: 1001 }));
        }
    }
}
</script>